<template>
  <v-app style="background-color: #f3f6f9">
      <v-main>
         
          <UsersComp />
      </v-main>
  </v-app>
</template>
<script>
import Auth from '@/services/auth.service.js'
import UsersComp from '@/components/users/UsersComp.vue'

export default {
  components: {
      UsersComp,
  },
  data() {
      return {
      };
  },
  methods: {
  },
  computed: {
  },
  mounted() {
      document.title = 'משתמשים | סים לחומרא'
      let auth = Auth.checkAuth();
      if (!auth) {
          return this.$router.replace({ name: "login" })
      }
  },
}
</script>
<style>

</style>