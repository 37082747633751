<template>
    <div>

        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">פנייה</span>
        </div>

        <ProgressShow v-if="progressShow" v-model="progressShow"></ProgressShow>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 40px;">
            <div style="background-color: white; width:80%; border-radius: 20px; padding: 20px;">
                <v-card v-if="ticket">
                    <v-card-title>
                        <div class="d-flex justify-space-between align-center w-100">
                            <span class="subject">נושא פנייה: {{ ticket.subject }}</span>
                            <v-chip color="#fdb721" text-color="white" style="padding: 0px 30px 0px 30px !important;">{{ "#"
                                + ticket.integer_id }}</v-chip>
                        </div>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                        <v-progress-circular v-if="progressShow" indeterminate color="primary"></v-progress-circular>
                        <div style="margin-bottom: 20px!important">
                            <span style="font-size: large; font-weight: 800; ">הודעות:</span>
                        </div>
                        <v-card v-for="message in allMessages" :key="message._id" class="mb-3">
                            <v-card-title style="padding-bottom: 0px !important;">
                                <div class="d-flex justify-space-between align-center w-100">
                                    <span style="color: #0d2c6d; font-size: 16px; font-weight: bold;">{{
                                        message.responder_id.name }}</span>
                                    <span style="color: gray; font-size: 15px;">{{ new
                                        Date(message.createdAt).toLocaleString() }}</span>
                                </div>
                            </v-card-title>
                            <v-divider style="margin: 1px!important;"></v-divider>
                            <v-card-text style="font-weight: 500;" v-html="formatMessage(message.message)"></v-card-text>
                        </v-card>

                        <v-divider class="my-4"></v-divider>

                        <div v-if="ticket.status === 'open'">
                            <v-card class="pa-4">
                                <v-textarea label="Reply" outlined full-width v-model="reply" multi-line
                                    rows="4"></v-textarea>
                                <div class="d-flex justify-space-between">
                                    <v-btn color="#0d2c6d" dark @click="handleReplySubmit">שלח תגובה</v-btn>
                                    <v-btn color="#0d2c6d" dark v-if="isOwner" @click="openAlertDialog(ticket)">
                                        סגור פנייה
                                    </v-btn>
                                </div>
                            </v-card>
                        </div>
                    </v-card-text>
                </v-card>
                <v-dialog v-model="alertDialog" max-width="400px">
                    <v-card>
                        <v-card-title class="headline">האם אתה בטוח שברצונך לסגור את הפנייה?</v-card-title>
                        <v-card-actions>
                            <ProgressShow v-if="progressDialog" v-model="progressDialog"></ProgressShow>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="alertDialog = false">בטל</v-btn>
                            <v-btn color="green darken-1" text @click="handleCloseTicket(ticketToClose)">אשר</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </div>
</template>
<script>
import Auth from '@/services/auth.service.js'
import ProgressShow from '@/components/widgets/progressShow.vue'
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import { mapState } from 'vuex';

export default {
    components: {
        ProgressShow,
        SnackBar
    },
    data() {
        return {
            progressShow: false,
            progressDialog: false,
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            id: this.$route.params.id,
            reply: '',
            allMessages: [],
            ticket: null,
            alertDialog: false,
            progressShow: false,
            ticketToClose: null,
            role: Auth.getUserRole(),
        };
    },
    methods: {
        async getTicketMessagesList() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const params = { id: this.id };
                let api = process.env.VUE_APP_BASE_URL + `/tickets/get_ticket_messages?ticketId=${this.id}`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.ticket = jsonObject;
                    
                    this.allMessages = JSON.parse(JSON.stringify(jsonObject.responses));
                    this.handleMarkAsRead(jsonObject, params);
                }
            } catch (error) {
                console.log(error);
                this.progressShow = false;
                this.showSnackBar("Error get tickets: " + error, "red");
            }
        },
        async handleMarkAsRead(ticketArg, params) {
            try {
                const responses = ticketArg.responses;
                const lastResponse = responses[responses.length - 1];
                if (!lastResponse.read_at) {
                    let token = localStorage.getItem("token");
                    let api = process.env.VUE_APP_BASE_URL + `/tickets/mark_as_read?ticketId=${params.id}`;
                    const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                    if (res.status === 200) {
                        console.log(3333333);
                    }
                }
            } catch (error) {
                console.log(error);
                this.showSnackBar("Error mark as read: " + error, "red");
            }
            try {

            } catch (error) {
                console.log(error);
            }
        },
        formatMessage(message) {
            return message.split('\n').map(line => `<span>${line}</span>`).join('<br>');
        },
        async handleReplySubmit() {
            if (this.reply.trim()) {
                try {
                    this.progressShow = true;
                    let token = localStorage.getItem("token");
                    let api = process.env.VUE_APP_BASE_URL + `/tickets/reply_message`;
                    const myJson = JSON.stringify({
                        ticketId: this.id, newMessage: this.reply
                    })
                    const res = await fetch(api, ApiServices.requestOptions("POST", myJson, token));
                    const jsonObject = await res.json();
                    this.progressShow = false;
                    if (res.status >= 400) {
                        this.showSnackBar("שגיאה בשמירת התגובה", "red");
                    } else if (res.status === 200) {
                        this.allMessages.push(jsonObject);
                        this.reply = '';
                        this.showSnackBar('התגובה נשלחה בהצלחה!', 'green');
                    }
                } catch (error) {
                    console.log(error);
                    this.progressShow = false;
                    this.showSnackBar(`Error replay message: ${error}`, 'red');
                }
            }
        },
        openAlertDialog(ticket) {
            this.alertDialog = true;
            this.ticketToClose = ticket;
        },
        async handleCloseTicket(ticket) {
            try {
                this.progressDialog = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/tickets/close_ticket?ticketId=${ticket._id}`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בשמירת התגובה", "red");
                } else if (res.status === 200) {
                    this.ticket.status = 'close';
                    this.alertDialog = false;
                    this.showSnackBar('הפנייה נסגרה בהצלחה', 'green');
                }
            } catch (error) {
                console.log(error);
                this.showSnackBar(`Error close ticket: ${error}`, 'red');
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        isOwner() {
            const ownerEmail = this.ticket.email;
            const userEmail = Auth.getUserEmail();
            return ownerEmail === userEmail;
        },
        ...mapState({
            newResponse: state => state.newResponse,
            ticketId: state => state.ticketId,
        }),
    },
    watch: {
        newResponse(newValue, oldValue) {
            if (this.ticket._id === this.ticketId) {
                this.allMessages.push(newValue);
                this.ticket.responses.push(newValue);
            }
        }
    },
    mounted() {
        this.getTicketMessagesList();
    }
}
</script>
<style>
.subject {
    font-size: 18px;
    font-weight: 600;
    color: #0d2c6d;
}
</style>