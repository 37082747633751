<template >
    <div>

        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">קהילות</span>
        </div>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 50px;">
            <div style="background-color: white; width:90%; border-radius: 20px; padding: 20px; text-align: center;">
                <v-row>
                    <v-col cols="12" md="2" style="margin-top: 1%; display: flex; align-items: center;">
                        <v-btn color="black" dark @click="openCreateDIalog()">הוסף קהילה חדשה</v-btn>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%;">
                        <v-text-field hide-spin-buttons hide-details label="חיפוש" flat solo v-model="search"
                            class="text_fields"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%; display: flex; align-items: center;">
                        <div>
                            <span style="color: #bbbaba; font-weight: 600;">
                                סה"כ קהילות
                            </span>
                        </div>
                        <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                            {{ communities.length }}
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-data-table id="table1" :headers="headers" :items="communities" hide-default-footer :search="search"
                            :page.sync="currentPage" :items-per-page="itemsPerPage" @page-count="pageCount = $event">

                            <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                                <span class="table-headers-span">{{ header.text }}</span>
                            </template>

                            <template v-slot:item.levels="{ item, index }">
                                <tr>
                                    <td>{{ item.levels.length }}</td>
                                </tr>
                            </template>

                            <template v-slot:item.index="{ index }">
                                <tr>
                                    <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                </tr>
                            </template>

                            <template v-slot:item.actions="{ item, index }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                            @click="openEditDialog(item, index)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                            @click="openDeleteDialog(item, index)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                        <v-pagination v-model="currentPage" circle :length="pageCount"
                            :total-visible="7"></v-pagination>

                    </v-col>
                </v-row>

            </div>
        </div>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

        <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
            @communityCreated="newCommunitySaved" @communityEdited="editedCommunitySaved"> </AddEditDialog>


        <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @itemDeleted="communityDeleted" />
    </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import AddEditDialog from '@/components/admin/communities/dialogs/AddEditDialog'
import AlertDialog from '@/components/admin/communities/dialogs/AlertDialog'
import Utils from "@/util/utils";
import SharedFunctions from "@/shared/SharedFunctions";

export default {
    components: {
        SnackBar,
        AddEditDialog,
        AlertDialog
    },
    data: () => ({
        headers: [
            { text: '#', value: 'index' },
            { text: 'שם', value: 'name' },
            { text: 'טוקן', value: 'token' },
            { text: 'רמות סינון', value: 'levels' },
            { text: 'פעולות', value: 'actions' },
        ],
        communities: [],
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        search: "",
        alertDialog: false,
        addEditDialog: false,
        itemToDelete: null,
        formTitle: "",
        progressShow: false,
        currentPage: 1,
        pageCount: 0,
        itemsPerPage: 20,
    }),
    methods: {
        async getCommunities() {
            this.communities = await SharedFunctions.getCommunities();
        },
        openEditDialog(item, index) {
            const originalItem = this.communities.find(community => community._id === item._id);
            this.editedItem = originalItem;
            this.formTitle = "ערוך קהילה"
            this.addEditDialog = true
        },
        openCreateDIalog() {
            this.formTitle = "הוסף קהילה חדשה"
            this.editedItem = {}
            this.addEditDialog = true
        },
        openDeleteDialog(item, index) {
            this.itemToDelete = item
            this.formTitle = "האם אתה בטוח שברצונך למחוק את הקהילה?"
            this.alertDialog = true
        },
        newCommunitySaved(item, text, color) {

            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            this.communities.push(item);

        },
        editedCommunitySaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.communities.findIndex(community => community._id === item._id);
            Object.assign(this.communities[index], item);
        },
        communityDeleted(item, text, color) {
            if (!item) {
                this.showSnackBar(text, color)
                return
            }
            const index = this.communities.findIndex(community => community._id === item._id);
            this.communities.splice(index, 1);
            this.showSnackBar(text, color)
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    created() {
        this.getCommunities();
    },
}
</script>
<style >
#table1.v-data-table .v-data-table-header th {
    background-color: #f3f6f9 !important;
    height: 50px !important;
    text-align: center !important;
    border-left: 1px solid #cccccc5e !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 17px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 40px !important;
    box-shadow: 0 6px 20px rgba(119, 119, 119, 0.15) !important;
    border-radius: 20px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #0d2c6d;
    /* border-left: 1px solid #cccccc5e !important; */
    text-align: center !important;
    border: none !important;
}

.text_fields {
    border: 1px solid #eee;
    border-radius: 13px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.table-spacing {
    height: 20px;
}

/* .v-data-table__wrapper table {
    border-collapse: collapse;
}

.v-data-table__wrapper td,
.v-data-table__wrapper th {
    border: none;
} */

/* ::v-deep table {
  border-collapse: separate !important;
  border-spacing: 0 10px!important 
} */
</style>
    