import socket from './socketService';
import EventBus from './eventBus';

const SocketPlugin = {
  install(Vue) {
    Vue.prototype.$socket = socket;
    Vue.prototype.$bus = EventBus;

    socket.on('newUserReply', ({ ticketId, newResponse, subject }) => {
      console.log(1111);
      EventBus.$emit('newUserReply', { ticketId, newResponse, subject });
    });

    socket.on('newAdminReply', ({ ticketId, newResponse, subject }) => {
      console.log(222222);
      EventBus.$emit('newAdminReply', { ticketId, newResponse, subject });
    });

    socket.on('newUserTicket', ({ ticketCreated, subject, message }) => {
      console.log(33333);
      EventBus.$emit('newUserTicket', { ticketCreated, subject, message });
    });

    socket.on('newAdminTicket', ({ ticketCreated, subject, message }) => {
      console.log(4444);
      EventBus.$emit('newAdminTicket', { ticketCreated, subject, message });
    });
  }
};

export default SocketPlugin;