<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field label="מספר מנוי" type="number" hide-spin-buttons outlined dense
                                    v-model="itemEdited.manui_number" required
                                    :rules="[(v) => !!v || 'שדה חובה', (v) => (v.length === 10) || 'מספר מנוי חייב להכיל בדיוק 10 תווים', validateManuiStart]"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text @click="updatePhone()">
                                עדכן
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            manui_number: "",
        },
    }),
    methods: {
        async updatePhone() {
            if (this.$refs.form.validate()) {
                const myJSON = JSON.stringify({
                    manuiNumber: this.itemEdited.manui_number, manuiId: this.itemToEdit._id,
                });
                this.progressShow = true;
                let token = localStorage.getItem("token");
                try {
                    let api = process.env.VUE_APP_BASE_URL + "/manuim/edit_manui_phone";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", myJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("itemEdited", this.itemEdited, "מספר המנוי עודכן בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved edited phone: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        validateManuiStart(v) {
            const arrayKosherPrefix = ["05041", "05271", "05276", "05484", "05485", "05331", "05341", "05832", "05567"];
            // Check if the value doesn't start with any of the prefixes
            const isValidPrefix = arrayKosherPrefix.some(prefix => v.startsWith(prefix));
            if (!isValidPrefix) {
                return "המספר סים שהזנתם אינו תואם לקידומת הכשרה";
            }
            // If all checks pass, return true
            return true;
        }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
    },
}
</script>
<style></style>