<template>
    <v-app style="background-color: #f3f6f9">
        <v-main>
           
            <TicketsComp />
        </v-main>
    </v-app>
  </template>
  <script>
  import Auth from '@/services/auth.service.js'
  import TicketsComp from '@/components/tickets/TicketsListComp.vue'
  
  export default {
    components: {
        TicketsComp,
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        document.title = 'פניות | סים לחומרא'
        let auth = Auth.checkAuth();
        if (!auth) {
            return this.$router.replace({ name: "login" })
        }
    },
  }
  </script>
  <style>
  
  </style>