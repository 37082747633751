<template>
    <div>

        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">פניות</span>
        </div>

        <ProgressShow v-if="progressShow" v-model="progressShow"></ProgressShow>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 40px;">
            <div style="background-color: white; width:80%; border-radius: 20px; padding: 20px;">
                <v-card>
                    <v-card-title style="padding-bottom: 0px !important">
                        רשימת פניות
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <div>
                            <v-row style="margin-bottom: 10px;">
                                <v-col cols="12" md="3">
                                    <v-text-field hide-spin-buttons hide-details label="חיפוש" flat solo v-model="search"
                                        @keyup.enter="getSearchTickets" class="text_fields"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2" style="display: flex; align-items: center;">
                                    <div>
                                        <span style="color: #bbbaba; font-weight: 600;">
                                            סה"כ פניות
                                        </span>
                                    </div>
                                    <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                                        {{ totalTickets }}
                                    </div>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select hide-spin-buttons hide-details label="סינון לפי" flat solo :items="typesList"
                                        v-model="filterType" :item-text="'hebrewName'" :item-value="'value'"
                                        class="text_fields"></v-select>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select style="max-width: 150px;" :items="[20, 50, 100, 500, 1000]"
                                        class="text_fields" v-model="itemsPerPage" hide-details flat solo
                                        label="שורות בעמוד" type="number" min="-1" max="15"
                                        @input="itemsPerPage = parseInt($event, 10)"></v-select>
                                </v-col>
                            </v-row>
                            <div v-if="tickets[0]" v-for="(ticket, index) in tickets" :key="ticket._id">
                                <v-card flat outlined
                                    style="padding: 20px 0px 20px 50px; display: flex; align-items: center;"
                                    :style="{ backgroundColor: setBackColor(ticket) }"
                                    @click="navigateToTicket(ticket._id)">
                                    <!-- Centered position number -->
                                    <div style="width: 50px; margin-right: 10px; font-weight: bold;">
                                        {{ calculatePosition(index) }}
                                    </div>

                                    <!-- Left-aligned content -->
                                    <div style="flex: 1;">
                                        <v-row style="padding: 0px !important;">
                                            <v-col cols="12" sm="8" md="8" lg="10" style="padding: 0px !important;">
                                                <v-typography class="subject">{{ ticket.subject }}</v-typography>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4" lg="2" style="padding: 0px !important;">
                                                <div class="d-flex justify-end">
                                                    <v-chip color="#fdb721" text-color="white"
                                                        style="padding: 0px 30px 0px 30px !important;">
                                                        #{{ ticket.integer_id }}
                                                    </v-chip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row style="padding: 0px !important;">
                                            <v-col cols="12" sm="8" md="8" lg="10" style="padding: 0px !important;">
                                                <v-typography v-if="ticket.status !== 'close'"
                                                    class="text-secondary caption">
                                                    תגובה אחרונה ע"י <span class="font-weight-bold">{{
                                                        ticket.responses[ticket.responses.length - 1].responder_id.name
                                                    }}</span>
                                                    <span> בתאריך {{ new Date(ticket.responses[ticket.responses.length -
                                                        1].createdAt).toLocaleString() }}</span>
                                                </v-typography>
                                                <v-typography v-else class="text-black caption">
                                                    הפנייה נסגרה
                                                </v-typography>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4" lg="2" class="d-flex justify-end"
                                                style="padding: 0px !important;">
                                                <v-typography class="text-secondary caption">
                                                    {{ new Date(ticket.createdAt).toLocaleString() }}
                                                </v-typography>
                                            </v-col>
                                        </v-row>
                                        <v-row style="padding: 0px !important;">
                                            <v-col cols="12" style="padding: 0px !important;">
                                                <v-typography class="caption" no-wrap ellipsis>
                                                    {{ ticket.responses[ticket.responses.length - 1].message.slice(0, 500)
                                                    }}
                                                    <span
                                                        v-if="ticket.responses[ticket.responses.length - 1].message.length > 500">...</span>
                                                </v-typography>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>

                            </div>
                        </div>
                    </v-card-text>
                </v-card>
                <v-pagination v-model="currentPage" circle :length="Math.ceil(totalTickets / itemsPerPage)"
                    :total-visible="10" class="my-2" color="primary" align-center @input="getTicketsList"></v-pagination>
            </div>
        </div>


        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
<script>
import ProgressShow from '@/components/widgets/progressShow.vue'
import Auth from '../../services/auth.service'; // adjust the import path as needed
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import { mapState } from 'vuex';

export default {
    components: {
        ProgressShow,
        SnackBar
    },
    data() {
        return {
            tickets: [],
            progressShow: false,
            totalTickets: 0, // Store total tickets count here
            currentPage: 1,
            itemsPerPage: 20,
            snackbar: false,
            filter: "",
            snackbarColorBt: "green",
            snacbarText: "",
            filterType: "all",
            typesList: [{ hebrewName: "הצג הכל", value: "all" }, { hebrewName: "לא נקראו", value: "unread" }, { hebrewName: "נסגרו", value: "close" }],
            search: "",
        };
    },
    computed: {
        ...mapState({
            newTicket: state => state.newTicket,
            newResponse: state => state.newResponse,
            ticketId: state => state.ticketId,
        }),
        isAdmin() {
            return Auth.getUserRole() === 'admin';
        },
    },
    watch: {
        newTicket(newValue, oldValue) {
            this.tickets.unshift(newValue);
        },
        newResponse(newValue, oldValue) {
            const ticket = this.tickets.findIndex((ticket) => ticket._id === this.ticketId);
            this.tickets[ticket].responses.push(newValue);
        },
        itemsPerPage() {
            this.getTicketsList();
        },
        currentPage() {
            this.getTicketsList();
        },
        filterType() {
            this.getTicketsList();
        }
    },
    methods: {
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async getTicketsList() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const api = `${process.env.VUE_APP_BASE_URL}/tickets/get_tickets_list?page=${this.currentPage}&limit=${this.itemsPerPage}&filter=${this.filterType}`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status >= 400) {
                    this.progressShow = false;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.tickets = jsonObject.tickets; // Update with the tickets array
                    this.totalTickets = jsonObject.totalTickets; // Update with total tickets count
                    this.progressShow = false;
                }
            } catch (error) {
                this.showSnackBar("Error get tickets: " + error, "red");
            }
        },
        async getSearchTickets() {
            try {
                if (!this.search) {
                    this.getTicketsList();
                    return
                }
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const JsonBody = JSON.stringify({ content: this.search.trim(), filter: this.filterType });
                this.tickets = [];
                let api = process.env.VUE_APP_BASE_URL + "/tickets/get_search_tickets";
                const res = await fetch(api, ApiServices.requestOptions("POST", JsonBody, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.tickets = jsonObject.tickets; // Update with the tickets array
                    this.totalTickets = jsonObject.totalTickets; // Update with total tickets count
                    console.log(this.tickets);
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get ticket search: " + error, "red");
            }
        },
        navigateToTicket(ticketId) {
            this.$router.push({ name: 'TicketDetails', params: { id: ticketId } });
        },
        setBackColor(ticket) {
            return ticket.responses[ticket.responses.length - 1].read_at === null &&
                ticket.responses[ticket.responses.length - 1].responder_id.role !== Auth.getUserRole()
                ? 'rgba(34, 90, 211, 0.15)'
                : ticket.status === 'close'
                    ? 'rgba(105, 99, 99, 0.15)'
                    : 'white';
        },
        calculatePosition(index) {
            return (this.currentPage - 1) * this.itemsPerPage + index + 1;
        },
    },
    mounted() {
        this.getTicketsList();
    },
}
</script>
<style>
.subject {
    font-size: 18px;
    font-weight: 600;
    color: #0d2c6d;
}
</style>