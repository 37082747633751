<template >
    <div>

        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">מנויים לקוח - {{ customerName }}</span>
        </div>

        <ProgressShow v-if="progressShow" v-model="progressShow"></ProgressShow>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 50px;">
            <div style="background-color: white; width:90%; border-radius: 20px; padding: 20px; text-align: center;">
                <v-row>
                    <v-col cols="12" md="3" style="margin-top: 1%;">
                        <v-text-field hide-spin-buttons hide-details label="חיפוש" flat solo v-model="search"
                            class="text_fields"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%; display: flex; align-items: center;">
                        <div>
                            <span style="color: #bbbaba; font-weight: 600;">
                                סה"כ מנויים
                            </span>
                        </div>
                        <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                            {{ manuim.length }}
                        </div>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%; display: flex; align-items: center;">
                        <v-select style="max-width: 100px; margin-right: 30px;" :items="[20, 50, 100, 500, 1000]"
                            class="text_fields" v-model="itemsPerPage" hide-details flat solo label="שורות בעמוד"
                            type="number" min="-1" max="15" @input="itemsPerPage = parseInt($event, 10)"></v-select>
                    </v-col>

                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-data-table id="table1" :headers="headers" :items="manuim" hide-default-footer :search="search"
                            :page.sync="currentPage" :items-per-page="itemsPerPage" @page-count="pageCount = $event">

                            <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                                <span class="table-headers-span">{{ header.text }}</span>
                            </template>

                            <template v-slot:item.status="{ item }">
                                <v-icon :color="getIconColorForStatus(item.status)">
                                    {{ getIconForStatus(item.status) }}
                                </v-icon>
                                <span style="margin-right: 10px;">{{ item.status === "active" ? "פעיל" : item.status ===
                                    "not_active" ? "לא פעיל" : "בתהליך" }}</span>
                            </template>

                            <template v-slot:item.manui_number="{ item }">
                                <span>{{ item.manui_number }}</span>
                                <v-tooltip bottom v-if="item.status === 'proccess' && item.manui_number && role === 'admin'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-right: 17px;"
                                            @click="openEditPhoneDialog(item)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עדכון מספר מנוי</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:item.sim_number="{ item }">
                                <span>{{ item.sim_number }}</span>
                                <v-tooltip bottom v-if="item.status === 'proccess' && role === 'admin'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-right: 17px;"
                                            @click="openEditSimDialog(item)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עדכון מספר סים</span>
                                </v-tooltip>
                            </template>


                            <template v-slot:item.plan_id.name="{ item }">
                                <span>{{ item.plan_id.name }}</span>
                                <v-tooltip bottom v-if="item.status === 'active'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-right: 17px;"
                                            @click="openEditPlanDialog(item)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עדכון חבילה</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:item.index="{ index }">
                                <tr>
                                    <!-- Calculate the correct index based on the current page and items per page -->
                                    <!-- Assuming page is 1-based, adjust calculation if your page numbering starts from 0 -->
                                    <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                </tr>
                            </template>

                            <template v-slot:item.actions="{ item, index }">
                                <v-tooltip bottom v-if="role === 'admin' && item.status !== 'active'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 17px;"
                                            @click="openEditDialog(item, index)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="role !== 'agent'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                            @click="createTicket(item, index)">
                                            mdi-email-fast-outline
                                        </v-icon>
                                    </template>
                                    <span>פתח פנייה למנוי זה</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="role === 'admin' && item.status !== 'active'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium class="me-3"
                                            @click="openDeleteDialog(item, index)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                        <v-pagination v-model="currentPage" circle :length="pageCount" :total-visible="7"></v-pagination>

                    </v-col>
                </v-row>

            </div>
        </div>
        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

        <EditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
            :customerId="customerId" @itemEdited="editedItemSaved" />

        <EditPlanDialog v-model="editPlanDialog" v-if="editPlanDialog" :formTitle="formTitle" :itemToEdit="editedItem"
         @itemEdited="editedItemSaved" />

         <EditSimDialog v-model="editSimDialog" v-if="editSimDialog" :formTitle="formTitle" :itemToEdit="editedItem"
         @itemEdited="editedItemSaved" />

         <EditPhoneDialog v-model="editPhoneDialog" v-if="editPhoneDialog" :formTitle="formTitle" :itemToEdit="editedItem"
         @itemEdited="editedItemSaved" />

        <CreateTicketDialog v-model="sendMessageDialog" v-if="sendMessageDialog" :messageItem="messageItem"
            :customerId="customerId" />

        <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @itemDeleted="itemDeleted" />

    </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils";
import Auth from '@/services/auth.service';
import EditDialog from '@/components/manuim/dialogs/EditDialog'
import EditPlanDialog from '@/components/manuim/dialogs/EditPlanDialog'
import EditSimDialog from '@/components/manuim/dialogs/EditSimDialog'
import EditPhoneDialog from '@/components/manuim/dialogs/EditPhoneDialog'
import CreateTicketDialog from '@/components/manuim/dialogs/CreateTicketDialog'
import ProgressShow from '@/components/widgets/progressShow.vue'
import AlertDialog from '@/components/manuim/dialogs/AlertDialog'

export default {

    components: {
        SnackBar,
        ProgressShow,
        EditDialog,
        CreateTicketDialog,
        AlertDialog,
        EditPlanDialog,
        EditSimDialog,
        EditPhoneDialog
    },
    data: () => ({
        headers: [
            { text: '#', value: 'index' },
            { text: 'מנוי', value: 'manui_number' },
            { text: 'מפעיל', value: 'plan_id.operating_company' },
            { text: 'מספר סים', value: 'sim_number' },
            { text: 'סוג', value: 'type' },
            { text: 'חבילה', value: 'plan_id.name' },
            { text: 'סטטוס', value: 'status' },
            { text: 'רמת סינון', value: 'community_level' },
            { text: 'נוצר בתאריך', value: 'createdAt' },
            { text: 'עודכן בתאריך', value: 'updatedAt' },
            { text: 'פעולות', value: 'actions', sortable: false },
        ],
        overlay: false,
        manuim: [],
        addEditDialog: false,
        editPlanDialog: false,
        editPhoneDialog: false,
        editSimDialog: false,
        sendMessageDialog: false,
        formTitle: "",
        editedItem: {},
        messageItem: {},
        itemToDelete: {},
        alertDialog: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        search: "",
        progressShow: false,
        currentPage: 1,
        pageCount: 0,
        itemsPerPage: 20,
        customerId: "",
        customerName: "",
        role: Auth.getUserRole()
    }),
    methods: {
        async getCustomerManuim() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let MyJSON = JSON.stringify({ customer_id: this.customerId });
                let api = process.env.VUE_APP_BASE_URL + `/manuim/get_customer_manuim`;
                const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status >= 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.manuim = jsonObject;
                    this.formatDetails();
                    console.log(this.manuim);
                    this.progressShow = false;
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.manuim.forEach(manui => {
                manui.createdAt = Utils.formatDateString(manui.createdAt),
                    manui.updatedAt = Utils.formatDateString(manui.updatedAt)
            });
        },
        getIconForStatus(status) {
            switch (status) {
                case 'active': return 'mdi-check-circle';
                case 'not_active': return 'mdi-cancel';
                case 'proccess': return 'mdi-clock-outline';
                default: return 'rgb(131, 124, 124)';
            }
        },
        getIconColorForStatus(status) {
            switch (status) {
                case 'active': return 'rgb(13, 138, 13)';
                case 'not_active': return 'rgb(206, 18, 18)';
                case 'proccess': return 'rgb(207, 142, 20)';
                default: return '';
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        openEditDialog(item, index) {
            this.editedItem = item;
            this.formTitle = "ערוך פרטי מנוי"
            this.addEditDialog = true
        },
        openEditPlanDialog(item) {
            this.editedItem = item;
            this.formTitle = "עדכון חבילה למנוי"
            this.editPlanDialog = true
        },
        openEditPhoneDialog(item) {
            this.editedItem = item;
            this.formTitle = "עדכון מספר מנוי"
            this.editPhoneDialog = true
        },
        openEditSimDialog(item) {
            this.editedItem = item;
            this.formTitle = "עדכון מספר סים"
            this.editSimDialog = true
        },
        openDeleteDialog(item, index) {
            this.itemToDelete = item
            this.formTitle = "האם אתה בטוח שברצונך למחוק את הקהילה?"
            this.alertDialog = true
        },
        editedItemSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.manuim.findIndex(manui => manui._id === item._id);
            Object.assign(this.manuim[index], item);
        },
        itemDeleted(item, text, color) {
            if (!item) {
                this.showSnackBar(text, color)
                return
            }
            const index = this.manuim.findIndex(manui => manui._id === item._id);
            this.manuim.splice(index, 1);
            this.showSnackBar(text, color)
        },
        createTicket(item) {
            this.messageItem = item;
            this.sendMessageDialog = true
        }
    },
    created() {
        this.customerName = this.$route.params.name;
        this.customerId = this.$route.params.id;
        this.getCustomerManuim();
    },
}
</script>
<style >
#table1.v-data-table .v-data-table-header th {
    background-color: #f3f6f9 !important;
    height: 50px !important;
    text-align: center !important;
    border-left: 1px solid #cccccc5e !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 17px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 40px !important;
    box-shadow: 0 6px 20px rgba(119, 119, 119, 0.15) !important;
    border-radius: 20px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #0d2c6d;
    /* border-left: 1px solid #cccccc5e !important; */
    text-align: center !important;
    border: none !important;
}

.text_fields {
    border: 1px solid #eee;
    border-radius: 13px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.table-spacing {
    height: 20px;
}

/* .v-data-table__wrapper table {
    border-collapse: collapse;
}

.v-data-table__wrapper td,
.v-data-table__wrapper th {
    border: none;
} */

/* ::v-deep table {
  border-collapse: separate !important;
  border-spacing: 0 10px!important 
} */
</style>
    