<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    label="מספר סים" type="number" hide-spin-buttons outlined dense
                                    v-model="itemEdited.sim_number" required
                                    :rules="[(v) => !!v || 'שדה חובה', (v) => validateSimStart(v) ||
                                        `המספר סים שהזנתם אינו תואם למפעיל ${itemEdited.plan_id.operating_company} שבחרתם`, validateSimLength]"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text @click="updateSim()">
                                עדכן
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            sim_number: "",
            plan_id: {
                operating_company: "",
            }
        },
    }),
    methods: {
        async updateSim() {
            if (this.$refs.form.validate()) {
                const myJSON = JSON.stringify({
                    manuiSim: this.itemEdited.sim_number, manuiId: this.itemToEdit._id,
                });
                this.progressShow = true;
                let token = localStorage.getItem("token");
                try {
                    let api = process.env.VUE_APP_BASE_URL + "/manuim/edit_manui_sim";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", myJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("itemEdited", this.itemEdited, "מספר הסים עודכן בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved edited sim: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        validateSimStart(value) {
      // Replace "123" with the specific digits you want to validate
      if (this.itemEdited.plan_id.operating_company === "hotMobile") {
        const validStartDigits = /^8997207/;
        return validStartDigits.test(value);
      }else if (this.itemEdited.plan_id.operating_company === "partner") {
        const validStartDigits = /^8997201/;
        return validStartDigits.test(value);
      }
     
    },
    validateSimLength(v) {
      if (this.itemEdited.plan_id.operating_company === 'partner' && v.length !== 20) {
        return 'מספר סים חייב להכיל בדיוק 20 תווים עבור Partner';
      } else if (this.itemEdited.plan_id.operating_company === 'hotMobile' && v.length !== 19) {
        return 'מספר סים חייב להכיל בדיוק 19 תווים עבור Hot Mobile';
      }
      return true;
    }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        
    },
}
</script>
<style></style>