import jwt_decode from 'jwt-decode';


class Auth {
  constructor() {
  }

  static checkAuth() {

    const token = localStorage.getItem('token');
    if (token) {
        const decodedToken = jwt_decode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
            // Token has expired
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }

  }

 static getUserRole(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_role'];
  } else {
    return false;
  }
 }

 static getUserId(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_id'];
  } else {
    return false;
  }
 }

 static getUserSetupFee(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_setup_fee'];
  } else {
    return false;
  }
 }

 static getUserName(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_name'];
  } else {
    return false;
  }
 }

 static getUserEmail(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_email'];
  } else {
    return false;
  }
 }

}

export default Auth;