<template>
    <v-dialog v-model="openMode" max-width="1000px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.name" label="שם הקהילה" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.token" hide-spin-buttons label="טוקן"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text
                                @click="formTitle === 'ערוך קהילה' ? saveEditedItem() : saveNewItem()">
                                שמור
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>

                    <div v-if="formTitle === 'ערוך קהילה'">
                        <v-form @submit.prevent="submitHandler" ref="form2">
                            <v-row style="margin-top: 30px;">

                                <v-col cols="12" sm="5">
                                    <v-text-field v-model="newLevel.level_name" label="שם סינון"
                                        :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="5">
                                    <v-text-field v-model="newLevel.level_code" label="קוד סינון" hide-spin-buttons type="number"
                                        :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2"
                                    style="display: flex; justify-content: center; align-items: center;">
                                    <v-btn small color="black" dark @click="saveNewLevel()">
                                        הוסף רמת סינון
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-row style="display: flex; justify-content: center;">
                            <v-col sm="12" md="8">
                                <div style="background-color: white; border-radius: 15px; ">
                                    <div>
                                        <div class="d-flex justify-center pt-1"
                                            style="background-color: rgb(218, 225, 235); border-radius: 15px 15px 0px 0px;">
                                            <span class="text-center "
                                                style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: #0d2c6d;">רמות
                                                סינון</span>
                                        </div>
                                        <div class="divider2"></div>
                                    </div>
                                    <div
                                        style="height: 350px; overflow-y: auto; background-color: white; border-radius: 0px 0px 15px 15px;">
                                        <v-data-table id="table1" :headers="levelsHeaders" :items="itemEdited.levels"
                                            sort-by="index" class="elevation-0" hide-default-footer
                                            :items-per-page="itemEdited.levels.length">

                                            <template v-slot:item.index="{ index }">
                                                <tr>
                                                    <td>{{ index + 1 }}</td>
                                                </tr>
                                            </template>

                                            <template v-slot:item.actions="{ item, index }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                                            @click="deleteLevel(item, index)">
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                    <span>מחיקה</span>
                                                </v-tooltip>
                                            </template>

                                            <template v-slot:no-data>
                                                <v-row v-if="progressShow" class="my-5">
                                                    <v-col class="d-flex justify-center">
                                                        <v-progress-circular indeterminate
                                                            color="#3F51B5"></v-progress-circular>
                                                    </v-col>
                                                </v-row>
                                                <div class="ma-5">
                                                    <span class="text-center"
                                                        style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                                                        לא נמצאו פריטים</span>
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            name: "",
            token: "",
            levels: [],
        },
        levelsHeaders: [
            { text: '#', value: 'index', sortable: false },
            { text: 'שם', value: 'level_name' },
            { text: 'מספר קוד', value: 'level_code' },
            { text: 'פעולות', value: 'actions', sortable: false },
        ],
        newLevel: {
            _id: "",
            level_name: "",
            level_code: "",
        }
    }),

    methods: {
        async saveNewItem() {

            if (this.$refs.form.validate()) {

                const ivrJSON = JSON.stringify({ community: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/communities/create_community";
                    const res = await fetch(api, ApiServices.requestOptions("POST", ivrJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited._id = jsonObject._id;
                        this.$emit("communityCreated", this.itemEdited, "הקהילה נוצרה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved new plan: " + error, "red");
                }
            }

        },
        async saveEditedItem() {

            if (this.$refs.form.validate()) {

                const ivrJSON = JSON.stringify({ community: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/communities/edit_community";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", ivrJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("communityEdited", this.itemEdited, "הקהילה עודכנה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved edited plan: " + error, "red");

                }
            }
        },
        async saveNewLevel() {

            if (this.$refs.form2.validate()) {

                const ivrJSON = JSON.stringify({ newLevel: this.newLevel, community: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/communities/create_level";
                    const res = await fetch(api, ApiServices.requestOptions("POST", ivrJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.newLevel._id = jsonObject;
                        this.itemEdited.levels.push(this.newLevel);
                        this.$emit("communityEdited", this.itemEdited, "רמת הסינון נוספה בהצלחה", "green");
                        this.newLevel = {
                            _id: "",
                            level_name: "",
                            level_code: "",
                        }
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved new level: " + error, "red");

                }
            }
        },
       async deleteLevel(item, index) {
            const ivrJSON = JSON.stringify({ level: item, community: this.itemEdited });
            this.progressShow = true;
            let token = localStorage.getItem("token");

            try {
                let api = process.env.VUE_APP_BASE_URL + "/communities/delete_level";
                const res = await fetch(api, ApiServices.requestOptions("DELETE", ivrJSON, token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar("Error: " + jsonObject, "red");
                } else if (res.status === 200) {
                    this.itemEdited.levels.splice(index, 1);
                    this.showSnackBar("רמת הסינון נמחקה בהצלחה!", "green");
                    this.$emit("itemDeleted", this.itemEdited, "רמת הסינון נמחקה בהצלחה!", "green");
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error saved new level: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך קהילה") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        }
    },
}
</script>
<style></style>
  