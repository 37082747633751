<template>
  <v-app>
    <v-main>
      <Navbar v-if="showNavbar" />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/widgets/navBar.vue';

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      inactivityTimeout: null,
      inactivityLimit: 60 * 60 * 1000, // 1 hours in milliseconds
    };
  },
  computed: {
    showNavbar() {
      return this.$route.name !== 'login';
    },
  },
  created() {
    this.setupActivityListeners();
  },
  methods: {
    setupActivityListeners() {
      window.addEventListener('keypress', this.resetTimer);
      window.addEventListener('click', this.resetTimer);
      // window.addEventListener('scroll', this.resetTimer);
      // window.addEventListener('mousemove', this.resetTimer);

      // Initialize the inactivity timer when the app is created
      this.resetTimer();
    },
    resetTimer() {
      // Clear existing timeout
      clearTimeout(this.inactivityTimeout);
      // Set a new timeout for 30 minutes
      this.inactivityTimeout = setTimeout(this.handleInactivity, this.inactivityLimit);

      // Update the last active timestamp in localStorage
      localStorage.setItem('lastActive', Date.now());
    },
    handleInactivity() {
      // Get the last active timestamp from localStorage
      const lastActive = localStorage.getItem('lastActive');
      const currentTime = Date.now();
      // Check if the user has been inactive for more than 30 minutes
      if (currentTime - lastActive >= this.inactivityLimit) {
        // Handle inactivity (e.g., logout, show alert, etc.)
        console.log('User has been inactive for 30 minutes');
        // Optionally, you can log the user out or redirect to the login page
        this.logout();
      }
    },
    logout() {
      // Clear user session and redirect to login page
      // Clear any authentication tokens or user data here
      localStorage.removeItem("token");
      localStorage.removeItem("logo");
      this.$router.replace({ name: "login" })
    },
  },
  beforeDestroy() {
    // Remove event listeners when the component is destroyed
    window.removeEventListener('keypress', this.resetTimer);
    window.removeEventListener('click', this.resetTimer);
    // window.removeEventListener('scroll', this.resetTimer);
    // window.removeEventListener('mousemove', this.resetTimer);
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

::-webkit-scrollbar-thumb {
  background-color: #0d2c6d;
  border-radius: 3px;
}
</style>
