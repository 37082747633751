<template>
    <v-app style="background-color: #f3f6f9">
        <v-main>
           
            <CustomersComp />

        </v-main>
    </v-app>
</template>
<script>
import Auth from '@/services/auth.service.js'
import CustomersComp from '@/components/customers/CustomersComp.vue'

export default {
    components: {
        CustomersComp,
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        document.title = 'לקוחות | סים לחומרא'
        let auth = Auth.checkAuth();
        if (!auth) {
            return this.$router.replace({ name: "login" })
        }
    },
}
</script>
<style>
</style>