<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">פתיחת פנייה</span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small style="margin-right: 10px;">
                            mdi-help-circle
                        </v-icon>
                    </template>
                    <span>שים לב, אין צורך להזין פרטי המנוי</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="subjectText" outlined dense label="נושא"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea v-model="messageText" outlined label="פרטי הודעה"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text @click="sendMessageToSeller()">
                                שמור
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';

export default {
    props: {
        messageItem: Object,
        customerId: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        messageText: "",
        subjectText: "",
    }),
    methods: {
        async sendMessageToSeller() {
            if (this.$refs.form.validate()) {

                const myJSON = JSON.stringify({ manui: this.messageItem, message: this.messageText, subject: this.subjectText });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/tickets/create_ticket";
                    const res = await fetch(api, ApiServices.requestOptions("POST", myJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.showSnackBar("הפנייה נפתחה בהצלחה", "green");
                        setTimeout(() => {
                            this.openMode = false;
                        }, 1000);
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved edited customer: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.itemEdited = JSON.parse(JSON.stringify(this.messageItem));
    },
}
</script>
<style></style>
  