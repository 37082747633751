<template>
  <div >
    <FormLogin />
  </div>
</template>
<script>
import FormLogin from '@/components/auth/FormLogin.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    FormLogin
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (auth) {
      this.$router.replace({ name: "home"})
    }
  },
}
</script>
<style>
</style>