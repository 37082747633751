<template >
  <div>
    <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
      <span style="font-size: large; color: #0d2c6d;">דף ראשי</span>
    </div>

    <ProgressShow v-if="progressShow" v-model="progressShow"></ProgressShow>

    <div style="width: 100%; margin-top: 30px;">
      <v-row style=" border-radius: 20px 20px 0 0; padding: 20px; display: flex; justify-content: center;">
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: x-large;">סה"כ לקוחות </span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 5px;">{{ customers.length }}</span>
        </v-col>
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: x-large;">סה"כ חיבורים </span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 5px;">{{ " " + manuim.length }}</span>
        </v-col>
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: x-large;">לקוחות חדשים מהיום </span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 5px;">{{ " " + customersFromToday }}</span>
        </v-col>
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: x-large;">חיבורים חדשים מהיום </span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 5px;">{{ " " + manuimFromToday }}</span>
        </v-col>
      </v-row>
      <v-row style=" border-radius: 20px 20px 0 0; padding: 20px; display: flex; justify-content: center; ">
        <v-col cols="8"
          style="background-color: white; height: 600px !important; display: flex; justify-content: center;">
          <canvas id="myChart"></canvas>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import Chart from 'chart.js/auto';
import ApiServices from '@/services/api.service'
import ProgressShow from '@/components/widgets/progressShow.vue'

export default {
  components: {
    SnackBar,
    ProgressShow
  },
  data: () => ({
    manuim: [],
    customers: [],
    customersFromToday: 0,
    manuimFromToday: 0,
    progressShow: false,
    chartInstance: null, // Keep track of the chart instance
  }),
  computed: {
  },
  methods: {
    async getDetails() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/statistics/get_details`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = true;
        if (res.status >= 400) {
          this.progressShow = true;
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.customers = jsonObject.customers;
          this.manuim = jsonObject.manuim;
          const customerCounts = this.countByDate(jsonObject.customers);
          const manuimCounts = this.countByDate(jsonObject.manuim);
          this.customersFromToday = this.todatyCount(customerCounts);
          this.manuimFromToday = this.todatyCount(manuimCounts);
          this.initializeChart(customerCounts, manuimCounts);
          this.progressShow = false;
        }
      } catch (error) {
        console.log(error);
        this.showSnackBar("Error get files: " + error, "red");
      }
    },
    // Helper function to count entries by date
    countByDate(entries) {
      const counts = {};
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() - 14); // 15 days including today

      // Initialize counts for each day to 0
      for (let i = 0; i < 15; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        const formattedDate = this.formatDate(date);
        counts[formattedDate] = 0;
      }

      // Filter and count entries
      entries.forEach(entry => {
        const entryDate = new Date(entry.createdAt);
        const formattedEntryDate = this.formatDate(entryDate);
        if (formattedEntryDate in counts) {
          counts[formattedEntryDate]++;
        }
      });

      return counts;
    },
    // Format date as 'DD-MM-YYYY'
    formatDate(d) {
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [day, month, year].join('-');
    },
    todatyCount(data) {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      const formattedToday = dd + '-' + mm + '-' + yyyy;
      return data[formattedToday] || 0;
    },
    initializeChart(customerCounts, manuimCounts) {
      const labels = Object.keys(customerCounts); // Assuming both arrays have the same keys
      const customerData = Object.values(customerCounts);
      const manuimData = Object.values(manuimCounts);

      const data = {
        labels: labels,
        datasets: [{
          label: 'לקוחות חדשים',
          data: customerData,
          fill: false,
          borderColor: '#ffba1aa9',
          tension: 0.1
        }, {
          label: 'חיבורים חדשים',
          data: manuimData,
          fill: false,
          borderColor: '#0d2b6dad',
          tension: 0.1
        }]
      };

      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      // Initialize the chart with these datasets
      var ctx = document.getElementById('myChart');
      this.chartInstance = new Chart(ctx, {
        type: 'line', // Change to 'line' type chart
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1, // Confirms that ticks are placed at whole numbers
                precision: 0 // Ensures no fractional part in ticks
              }
            },
          }
          // scales: {
          //   x: {
          //     stacked: true,
          //   },
          //   y: {
          //     stacked: true,
          //     beginAtZero: true,
          //     ticks: {
          //       stepSize: 1 // This ensures the scale steps are whole numbers
          //     }
          //   }
          // }
        }
      });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
  },
  mounted() {
    this.getDetails();
  },
  // watch: {
  //   calls(newVal, oldVal) {
  //     this.initializeChart();
  //   },
  // },
}
</script>
<style scoped>
.my-custom-col {
  margin-left: 25px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
</style>
    