<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6" v-if="itemEdited.type === 'מנוי חדש' && !isManuiNumberExist">
                                <v-text-field v-model="itemEdited.manui_number" label="מספר מנוי"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="isManuiNumberExist">
                                <v-select :items="status" :item-text="'hebrewName'" :item-value="'name'" hide-details
                                    v-model="itemEdited.status" outlined dense label="סטטוס">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text
                                @click="formTitle === 'ערוך פרטי מנוי' ? saveEditedItem() : saveNewItem()">
                                שמור
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        customerId: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            manui_number: "",
            status: "",
        },
        isManuiNumberExist: false,
        status: [{ hebrewName: "פעיל", name: "active" }, { hebrewName: "לא פעיל", name: "not_active" }, { hebrewName: "בתהליך", name: "proccess"}],
    }),
    methods: {
        async saveEditedItem() {
            if (this.$refs.form.validate()) {

                const myJSON = JSON.stringify({ customerId: this.customerId, manui: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/manuim/edit_manui";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", myJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("itemEdited", this.itemEdited, "המנוי עודכן בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved edited customer: " + error, "red");

                }

            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך פרטי מנוי") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
            if (this.itemEdited.manui_number) {
                this.isManuiNumberExist = true;
            }
        }
    },
}
</script>
<style></style>
  