
class Services {
  constructor() {
  }

  // RETURN A NICE DATE STRING
  static getCurrentDate(date) {

    let currentDate;
    currentDate = new Date();
    if (date) {
      currentDate = new Date(date);
    }
    let formattedDate;
    let formattedTime;
    formattedDate = `${String(currentDate.getDate()).padStart(2, "0")}/${String(currentDate.getMonth() + 1).padStart(2, "0")}/${currentDate.getFullYear()}`;
    formattedTime = `${String(currentDate.getHours()).padStart(2, "0")}:${String(currentDate.getMinutes()).padStart(2, "0")}:${String(currentDate.getSeconds()).padStart(2, "0")}`;
    return formattedDate + "," + formattedTime
  }

  static getFormatDate2(date) {

    let currentDate;
    currentDate = new Date();
    if (date) {
      currentDate = new Date(date);
    }
    let formattedDate;
    let formattedTime;
    formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
    formattedTime = `${String(currentDate.getHours()).padStart(2, "0")}:${String(currentDate.getMinutes()).padStart(2, "0")}`;
    return formattedDate + " " + formattedTime
  }



  static formatDateString(dateString) {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}-${month}-${year},${hours}:${minutes}:${seconds}`;
  }

}

export default Services;

