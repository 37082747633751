<template>
    <v-row v-model="openMode" class="my-5">
            <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
</template>
<script>
export default {
    props: {
        value: { type: Boolean, default: false },
    },
    methods: {
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>

  